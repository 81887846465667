<template>
  <div ref="scrollContainer" class="home-content">
    <div v-for="(item, index) in lastList" :key="index">
      <div class="date-title">{{ item.time }}</div>
      <div v-if="lastList.length">
        <VisitListItem
          v-for="(sonItem, sonIndex) in item.list"
          :key="sonIndex"
          :color="changeColor(item.time)"
          :item="sonItem"
          :is-sub="true"
        />
      </div>
    </div>
    <Empty v-if="!lastList.length && !loading" description="无数据" />
    <Loading
      v-if="loading"
      style="margin-top: 100px"
      type="spinner"
      color="#1989fa"
    />
    <Button
      class="floating"
      round
      type="info"
      icon="plus"
      @click="$router.push('insight-create')"
    >
      新建洞见
    </Button>
    <div style="height: 50px"></div>
  </div>
</template>
<script>
import VisitListItem from './components/visitListItem.vue';
import {Button, Empty, Loading} from 'vant';
import {fetchPhysicianMslInsights} from '@/api/kol';
import {getLocalStorage} from '@/utils';

export default {
  components: {
    VisitListItem,
    Button,
    Empty,
    Loading,
  },
  data() {
    return {
      allList: [],
      todayData: [],
      tomorrowData: [],
      previousData: [],
      lastList: [],
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  destroyed() {},
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        container.scrollTop = container.scrollHeight;
      });
    },
    async getList() {
      this.loading = true;
      const res = await fetchPhysicianMslInsights({insight_user_id: getLocalStorage('user_id')});
      this.allList = res.value;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      res.value.forEach((item) => {
        const itemDate = new Date(item.insight_time);
        itemDate.setHours(0, 0, 0, 0);

        if (itemDate.getTime() === today.getTime()) {
          this.todayData.push(item);
        } else if (
          itemDate.getTime() ===
          today.getTime() + 24 * 60 * 60 * 1000
        ) {
          this.tomorrowData.push(item);
        } else if (itemDate.getTime() < today.getTime()) {
          this.previousData.push(item);
        }
      });
      console.log('first', this.convertTo2DArray(res.value));
      this.lastList = this.convertTo2DArray(res.value);
      this.loading = false;
      this.scrollToBottom();
    },
    convertTo2DArray(inputArray) {
      const dateMap = {};

      for (let i = 0; i < inputArray.length; i++) {
        const element = inputArray[i];
        const callTime = element.insight_time?.split('T')?.[0]; // 截取日期部分
        const currentDate = new Date().toISOString().split('T')[0]; // 当前日期
        const nextDate = new Date(Date.now() + 24 * 60 * 60 * 1000)
          .toISOString()
          .split('T')[0]; // 明天的日期

        let time;
        if (callTime !== currentDate && callTime !== nextDate) {
          time = new Date(callTime).toLocaleDateString('zh-CN', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }); // 格式化日期为 "YYYY年MM月DD日"
        }
        if (callTime === currentDate) {
          time = '今天';
        }
        if (callTime === nextDate) {
          time = '明天';
        }

        if (Object.prototype.hasOwnProperty.call(dateMap, time)) {
          dateMap[time].list.push(element);
        } else {
          dateMap[time] = {time, list: [element]};
        }
      }

      const resultArray = Object.values(dateMap);
      return resultArray;
    },
    changeColor(e) {
      if (e === '今天') {
        return '#00BDBC';
      } else if (e === '明天') {
        return '#FD9700';
      } else {
        return '#B2B2B2';
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home-content {
  height: calc(100vh - 20px);
  padding: 10px;
  overflow-y: scroll;

  .date-title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
  }
  .floating {
    position: absolute;
    bottom: 90px;
    right: 10px;
  }
}
</style>
